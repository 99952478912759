<template>
  <div>
    <div class="pay_cap">
      Выберите карту для автопополнения
    </div>
    <div class="paydiv">
      <loading-spinner v-if="fetchingCards"/>
      <table v-else>
        <tr v-for="card in cardsList" :key="card.id" :class="{active: card.id == autopayCardId}">
          <td style="width: 200px;"><b>{{ card.type }} {{ card.num }}</b></td>
          <td>
            <loading-btn :loading="card.id == loading.toggle" :disabled="loading.toggle || loading.delete" @click="autopayToggle(card.id)">
              {{ card.id == autopayCardId ? 'Отключить' : 'Подключить' }}
            </loading-btn>
          </td>
          <td>
            <loading-btn :loading="card.id == loading.delete" :disabled="loading.toggle || loading.delete" @click="deleteCard(card.id)">
              Удалить
            </loading-btn>
          </td>
        </tr>
        <div v-if="!cardsList.length">
          Отстутствуют сохраненные карты для автопополнения.
          Для сохранения карты пополните счет с помощью банковсокй карты на любую сумму и нажмите кнопку "настроить автопополнение" на странице результатов платежа.
        </div>
      </table>
    </div>
    Автопополнение срабатывает при достижении баланса близкого к нулю. Сумма пополнения зависит от стоимости подключенных услуг.
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      autopayCardId: null,
      cardsList: [],
      fetchingCards: false,
      loading: {
        toggle: null,
        delete: null,
      },
    }
  },
  created() {
    this.fetchCardsList()
  },
  methods: {
    fetchCardsList() {
      this.fetchingCards = true
      api.esspCardsList()
        .then(data => {
          this.autopayCardId = data.autopayCardId
          this.cardsList = data.cards
        })
        .catch(() => {})
        .then(() => this.fetchingCards = false)
    },
    autopayToggle(cardId) {
      this.loading.toggle = cardId
      api.esppAutopayToggle(cardId)
        .catch(() => {})
        .then(() => {
          this.fetchCardsList()
          this.loading.toggle = null
        })
    },
    deleteCard(cardId) {
      if (!confirm('Вы действительно хотите удалить эту карту ?')) {
        return
      }
      this.loading.delete = cardId
      api.esppDeleteCard(cardId)
        .catch(() => {})
        .then(() => {
          this.fetchCardsList()
          this.loading.delete = null
        })
    },
  }
}
</script>

<style scoped>
.paydiv button {
  width: 100%;
}
.paydiv td:first-child {
  padding: 0 5px;
}
.paydiv tr.active {
  background-color: #eee;
}
</style>
