<template>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td valign="top">
        <div class="borderness" v-if="$auth.check('ROLE_INET_ACTIVATED')">
          <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab0">
            <tr>
              <td valign="top">
                <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab1">
                  <tr>
                    <td class="caption">Регистрация сетевого оборудования :.</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="info">
                      В случае смены сетевой карты или установки нового сетевого оборудования (ноутбука, роутера, WiFi-адаптера).
                      Вам необходимо перерегистрировать
                      <span class="tooltip" id="tooltip" title="Физический, аппаратный или MAC адрес. Уникальный идентификатор Вашего сетевого оборудования. Необходим для регистрации Вашего оборудования в нашей системе">
                        аппаратный (MAC) адрес
                      </span>.
                      Это нужно для корректного получения сетевых настроек и доступа ко всем ресурсам нашей сети.
                      Для проведения перерегистрации нажмите кнопку "Зарегистрировать сетевое оборудование".
                      Для корректной работы рекомендуем перезагрузить роутер и компьютер через 10 минут после регистрации оборудования.
                      <br />
                      Операция регистрации сетевого оборудования доступна не более 2 раз в сутки.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td width="250px">IP адрес Вашего лицевого счёта</td>
                          <td><span class="field">{{ $user().inet.ip }}</span></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;padding:10px">
                            <loading-btn :loading="linkingMac" @click="doLinkMac()" :disabled="!canLinkMac" style="width:300px;padding:1px 4px;margin:0px;font:11px Verdana, Arial, Helvetica, sans-serif">
                              Зарегистрировать сетевое оборудование
                            </loading-btn>

                            <br />
                            <sub style="color:#0C3" v-if="linkMacSuccess">
                              Ваше оборудование зарегистрировано успешно. Пожалуйста, перезагрузите роутер и компьютер через 10 минут.
                            </sub>
                            <sub style="color:#cc0031">
                              {{ linkMacError }}
                            </sub>
                            <br />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="confirm-overlay" v-if="confirmLinkMac">
          <div class="confirm-dialog">
            <b class="close-dialog" @click="confirmLinkMac = null">×</b>
            <div v-if="confirmLinkMac === 'gpon_to_ipoe'" >
              <div style="display: flex;">
                <div>
                  <img src="@/assets/img/warning-icon.png" style="width: 70px; margin-right: 25px;" />
                </div>
                <div>
                  Уважаемый абонент, в настоящий момент доступ к услугам Интернет осуществляется по технологии <b>GPON</b> через оптоволоконный кабель.<br/>
                  Если вы намерены задействовать кабель <b>"витая пара" (именно он подключен к оборудованию сейчас)</b>,
                  использовавшийся ранее при подключении по технологии <b>IPoE</b>, обращаем внимание,
                  что максимально возможная скорость Интернет будет ограничена возможностями технологии, а именно до 100 Мбит/с.<br/>
                  После перехода на технологию <b>IPoE</b> в течение <b>7 суток</b> вы сможете самостоятельно вернуться на <b>GPON</b>
                  подключив оптический кабель и пройдя регистрацию оборудования.
                  В случае если обратный переход на GPON не состоится в течение <b>7 суток,
                  будет осуществлён переход на тариф {{ gponToIpoe.tariffName }}</b> {{ gponToIpoe.tariffSpeed || 100 }} Мбит/сек,
                  а для возобновления пользования высокоскоростным соединением по оптоволокну (возврат на <b>GPON</b>)
                  будет необходим вызов мастера по тел: 205-01-01 (услуга может быть платной).
                </div>
              </div>
              <div class="confirm-actions">
                <label>
                  <input type="checkbox" v-model="confirmChangeLink" />
                  Я соглашаюсь с условиями перехода
                </label><br/><br/>
                <button @click="doLinkMac(true)" :disabled="!confirmChangeLink">Перейти на IPoE</button>
                <button @click="confirmLinkMac = null">Остаться на GPON</button>
              </div>
            </div>
            <div v-else-if="confirmLinkMac === 'ipoe_to_gpon'">
              Вы переключились на оптический кабель, желаете вернуться на предоставлении услуги по GPON?
              <div class="confirm-actions">
                <button @click="doLinkMac(true)">Подключится на GPON</button>
                <button @click="confirmLinkMac = null">Остаться на IPoE</button>
              </div>
            </div>
            <div v-else>
              <h1>Вы уверены ?</h1>
              <div class="confirm-actions">
                <button @click="doLinkMac(true)">Да</button>
                <button @click="confirmLinkMac = null">Нет</button>
              </div>
            </div>
          </div>
        </div>

        <br /><br />

        <div class="borderness">
          <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab0">
            <tr>
              <td valign="top">
                <form @submit.prevent="doPasswordChange">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab1">
                    <tr>
                      <td class="caption">Смена пароля :.</td><td>&nbsp;</td></tr>
                    <tr>
                      <td colspan="2" class="info">
                        <span class="h">Пароль должен соответствовать следущим условиям</span><br />
                        Пароль должен содержать от 6 до 16 прописных букв латинского алфавит, цифр и спец символов из списка:
                        <strong>. , _ -</strong>
                        <br />Не должен совпадать с логином <strong>{{ $user().login }}</strong> или ЛС.
                      </td>
                    </tr>
                    <tr>
                      <td width="180px">Ваш ЛС</td>
                      <th width="250px" style="font-weight:bold" id="login">
                        {{ $user().account }}
                      </th>
                    </tr>
                    <tr>
                      <td>Введите <b>текущий</b> пароль</td>
                      <th>
                        <input type="password" id="oldpwd" name="oldpwd" class="pwd" v-model="oldPassword" />
                        <div class="warn-message warn-message-error">
                          {{ passwordErrors.old_password }}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td>Введите <b>новый</b> пароль</td>
                      <th>
                        <input type="password" id="newpwd" name="password" class="pwd" v-model="newPassword" />
                        <div class="warn-message warn-message-error">
                          {{ passwordErrors.new_password }}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td>Повторите <b>новый</b> пароль</td>
                      <th>
                        <input type="password" id="renewpwd" name="renewpwd" class="pwd" v-model="newPasswordConfirm" />
                        <div class="warn-message warn-message-error">
                          {{ passwordErrors.new_password_confirm }}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td colspan="2" id="warn">
                        <div class="warn-message warn-message-info" v-if="passwordChanged">Пароль успешно изменен</div>
                        <div class="warn-message warn-message-error" v-if="passwordChangeError">При смене пароля произошла ошибка</div>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <th>
                        <loading-btn :disabled="!oldPassword || !newPassword" :loading="passwordChanging">
                          Изменить
                        </loading-btn>
                      </th>
                    </tr>
                  </table>
                </form>
              </td>
              <td width="380">
                <div class="passwords">
                  <div class="password-title">Как правильно составить пароль</div>
                  Пароль - самая важная защита вашего счёта. Отнеситесь к этому серьезно!
                  Если вы считаете, что кто-то имеет доступ к вашим данным, смените пароль.
                  <br /><br />
                  Не используйте простых паролей, вида '12345', 'qwerty', 'password' и т.п.
                  Не пишите в качестве пароля свои имя, фамилию, день рождения или номер телефона! Подобные пароли очень легко подобрать.
                  <br />
                  Советы:
                  <ul>
                    <li>Пароль должен быть легко запоминаемым, чтобы вы сами его не забыли.</li>
                    <li>Не сообщайте ваш пароль третьим лицам.</li>
                    <li>Не используйте данный пароль в других местах</li>
                    <li>Не сохраняйте пароль в браузере.</li>
                    <li>Создавайте сложные пароли с использованием букв, цифр и символов пунктуации: <strong>. , - _ </strong></li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <br /><br />
      </td>
    </tr>
  </table>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      passwordChanging: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordRules: [
        v => !!v || 'Поле обязательное для заполнения',
        v => v != this.$user().login || 'Пароль не должен совпадать с логином',
        v => v.length >= 6 || 'Минимум 6 симовлов',
        v => v.length <= 16 || 'Максимум 16 симовлов',
        v => !!v.match(/^[a-z0-9_.,-]+$/) || 'Вы используете недопустимые символы',
      ],
      passwordErrors: {},
      passwordChanged: false,
      passwordChangeError: false,

      linkMacSuccess: false,
      linkMacError: '',
      confirmLinkMac: null,
      confirmChangeLink: false,
      gponToIpoe: {},
    }
  },
  computed: {
    canLinkMac() {
      const inet = this.$user().inet || {}
      if (inet.is_mac_ok) {
        return false
      }
      return !this.linkMacSuccess
    },
    linkingMac() {
      return this.$store.getters.hasActiveAction('Service.Change.Inet')
    },
  },
  methods: {
    doPasswordChange() {
      this.passwordErrors = {}
      if (this.newPassword !== this.newPasswordConfirm) {
        this.$set(this.passwordErrors, 'new_password_confirm', 'Введеные пароли не совпадают')
        return
      }

      this.passwordChanging = true
      this.passwordChanged = false
      this.passwordChangeError = false

      api.changePassword(this.oldPassword, this.newPassword)
        .then(res => {
          if (res.success) {
            this.passwordChanged = true
            return
          }
          if (res.errors) {
            for (let err in res.errors) {
              this.$set(this.passwordErrors, err, res.errors[err])
            }
          }
        })
        .catch(() => this.passwordChangeError = true)
        .then(() => this.passwordChanging = false)
    },
    doLinkMac(confirmed) {
      this.confirmLinkMac = null
      this.confirmChangeLink = false
      this.linkMacError = ''
      this.$store.commit('START_ACTIVE_ACTION', 'Service.Change.Inet')
      api.linkMac(confirmed)
        .catch(() => ({message: 'Ошибка при регистрации оборудования'}))
        .then(res => {
          if (!res.success) {
            if (res.need_confirm) {
              this.confirmLinkMac = res.need_confirm
              this.gponToIpoe = res.new_tariff || {}
            }
            this.linkMacError = res.message
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Service.Change.Inet')
          }
        })
    },
  },
  ws: {
    private: {
      CompleteAction(e) {
        if (e.name === 'Service.Change.Inet') {
          this.linkMacSuccess = true
        }
      }
    }
  }
}
</script>

<style scoped>
.mac-address {
  border-bottom: 1px dotted;
  cursor: pointer;
}
.confirm-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.confirm-overlay .confirm-dialog {
  background-color: #fff;
  width: 600px;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
  padding: 30px;
  padding-top: 40px;
  border-radius: 3px;
  box-shadow: 0 0 3px 3px #666;
}
.confirm-dialog .close-dialog {
  cursor: pointer;
  float: right;
  margin-top: -40px;
  margin-right: -30px;
  padding: 15px;
}
.confirm-dialog .confirm-actions {
  text-align: center;
  margin-top: 30px;
}
.confirm-dialog .confirm-actions button {
  margin: 0 5px;
}
</style>
