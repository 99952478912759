<template>
  <div>
    <div class="warn-common">
      <span class="warn-message warn-message-error" v-if="errorMsg">
        Ошибка:
        <span>{{ errorMsg }}</span>
      </span>
    </div>
    <h1 class="main-title">Тарифный план «Игровой»</h1>
    <div class="body-text">
        Специальное предложение для любителей компьютерных онлайн игр. Вы получаете высокоскоростной доступ в Интернет и
        игровые преимущества в играх от:
        <ul>
            <li>Wargaming: World of Tanks, World of Warships, World of Warplanes</li>
            <!--
            <li>Фогейм: Lineage 2, Blade and Soul, Point Blank, Aion</li>
            <li>MY.GAMES: Warface, ArcheAge и СrossFire</li>
            -->
        </ul>
        <div>Каждый день в любимой игре вас ждет эксклюзивный комплект игровых опций.</div>
        <div>Стань сильнее в любимых играх с тарифом Игровой!</div>

        <div v-if="$user().tariff && $user().tariff.has_game_option">
          <div class="center" v-if="loading">
            <loading-spinner />
          </div>
          <div v-else>
            <div class="block-actions-time-counter" v-if="$user().is_blocked">
              <span v-if="hasLinks">Нельзя активировать опцию будучи в блокировке.</span>
              <span v-else>Для привязки игрового аккаунта Вам необходимо снять блокировку.</span>
            </div>
            <!-- игры wargaming -->
            <div class="wargaming mt20">
              <table>
                <tr>
                  <td class="platform-icon">
                    <img src="@/assets/img/gg_icon_wargaming.png" />
                  </td>
                  <td class="sub-title">
                    Игровая опция: Wargaming<br/>
                    <span :class="hasBonus('WG_WOT') ? 'active-option' : 'deactivate-option'">{{ hasBonus('WG_WOT') ? 'Активирована' : 'Не активирована' }}</span>
                    <span class="option-text" v-if="linkData('WG_WOT').profile_id">
                      для <a class="tdu" :href="`https://tanki.su/ru/community/accounts/${linkData('WG_WOT').profile_id || ''}`" target="_blank">
                        {{ linkData('WG_WOT').nickname || 'аккаунта' }}
                      </a>
                    </span>
                  </td>
                  <td class="action-button">
                    <button v-if="!hasBonus('WG_WOT')" :disabled="$user().is_blocked" class="link-account" @click="linkData('WG_WOT').linked ? unlink('WG_WOT') : linkWg()">
                      {{ linkData('WG_WOT').linked ? 'Сменить игровой аккаунт' : 'Привязать игровой аккаунт' }}
                    </button>
                    <button :disabled="$user().is_blocked || !linkData('WG_WOT').linked" class="activate-option" @click="toggleBonus('WG_WOT')">
                      {{ hasBonus('WG_WOT') ? 'Деактивировать' : 'Активировать' }} опцию
                    </button>
                  </td>
                </tr>
              </table>
              <div class="body-text">
                Всем абонентам тарифного плана с активированной опцией «Игры Wargaming» предоставляется эксклюзивный игровой комплект:
                <ul>
                  <li>
                    <span class="bold">World of Tanks</span> - танковый премиум аккаунт, эксклюзивный премиум танк VIII уровня Т44-100(Р),
                    слот в ангаре и 100% экипаж с навыком «Боевое братство»;
                  </li>
                  <li>
                    <span class="bold">World of Warships</span> - корабельный премиум аккаунт, эксклюзивный премиум крейсер VIII уровня «В.И.Ленин»,
                    слот в порту, командир с 10 очками навыка;
                  </li>
                  <li>
                    <span class="bold">World of Warplanes</span> - премиум аккаунт, эксклюзивный премиум самолет VII уровня Яковлев Як-3Т, слот в ангаре,
                    100% обученный экипаж с 1 очком умений.
                  </li>
                </ul>
              </div>
              <div class="button-help">
                <form action="http://gg.inetvl.ru/?fp#benefits/" method="get" target="_blank" style="float: left; margin: 0 15px 0 0;">
                  <button>Подробное описание предложения</button>
                </form>
                <form action="https://ru.wargaming.net/support/ru/" method="get" target="_blank">
                  <button>Часто задаваемые вопросы</button>
                </form>
              </div>
            </div>

          </div>
        </div>
        <div v-else>
          <br/>
          <div class="center" v-if="loading">
            <loading-spinner />
          </div>
          <div v-else-if="!gameData.game_tariff">
            <div class="block-actions-time-counter">Установка игрового тарифа невозможна, обратитесь в абонентский отдел</div>
          </div>
          <div v-else>
            <div class="body-text">
              Тарифный план «Игровой» - {{ gameData.game_tariff.cost }} руб/мес<br/><br/>
              В тариф входит:
              <ul>
                <li>Интернет 100 Мбит/с</li>
                <li>Игровая опция</li>
              </ul>
              Дополнительно услуги:
              <ul>
                <li>Видеосервис Wink - до 227 каналов и 5000 фильмов и сериалов – от 280 руб/мес</li>
                <li>Кабельное ТВ <span style="font-style: italic">(доступно при наличии тех возможности)</span></li>
              </ul>
            </div>
            <div class="connect-tariff-wrp">
              <div class="block-actions-time-counter" v-if="$user().is_blocked">Чтобы установить тариф игровой Вам необходимо снять блокировку</div>
              <loading-btn class="connect-tariff" :disabled="$user().is_blocked" :loading="tariffLinking" @click="tariffLink">
                Подключить Игровой Тариф
              </loading-btn>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      fetching: false,
      processing: false,
      gameData: {},
      errorMsg: '',
    }
  },
  computed: {
    tariffLinking() {
      return this.$store.state.activeActions.length > 0
    },
    loading() {
      return this.$store.state.activeActions.length > 0 || this.fetching || this.processing || this.gameData.in_progress
    },
    hasLinks() {
      return this.gameData.links && Object.keys(this.gameData.links).length > 0
    },
  },
  created() {
    this.errorMsg = this.$route.query.error
    if (this.errorMsg) {
      this.$router.replace({name: this.$route.name})
    }
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.fetching = true
      return api.gameFetchData()
        .then(data => {
          this.gameData = data
          this.fetching = false
        })
    },
    hasBonus(platform) {
      return this.gameData.bonus && this.gameData.bonus.includes(platform)
    },
    linkData(platform) {
      return (this.gameData.links && this.gameData.links[platform]) || {}
    },
    linkWg() {
      this.processing = true
      this.apiRequest(
        () => api.gameLinkWg(),
        () => this.processing = false,
        res => location = res.url
      )
    },
    unlink(platform) {
      if (!confirm('Вы уверены что хотите сменить Игровой аккаунт?')) {
        return
      }
      this.processing = true
      this.apiRequest(
        () => api.gameUnlnk(platform, this.linkData(platform).profile_id),
        () => this.processing = false,
        () => {
          this.fetchData()
          this.processing = false
        }
      )
    },
    toggleBonus(platform) {
      if (this.hasBonus(platform) && !confirm('Вы уверены что хотите деактивировать опцию?')) {
        return
      }
      this.processing = true
      const func = this.hasBonus(platform) ? api.gameDeactivateBonus : api.gameActivateBonus
      this.apiRequest(
        () => func(platform),
        () => this.processing = false,
        () => {
          this.gameData = {in_progress: true}
          this.processing = false
        }
      )
    },
    tariffLink() {
      if (!this.gameData.game_tariff) {
        return
      }
      this.$store.commit('START_ACTIVE_ACTION', 'Tariff.Link')
      this.apiRequest(
        () => api.tariffSetCurrent(this.gameData.game_tariff.id),
        () => this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Tariff.Link')
      )
    },
    apiRequest(func, errorCb, successCb) {
      this.errorMsg = ''
      func()
        .then(res => {
          if (!res.success) {
            return res.message
          }
          if (successCb) {
            successCb(res)
          }
        })
        .catch(() => 'В процессе выполнения операции произошла ошибка, попробуйте позже.')
        .then(err => {
          if (err) {
            this.errorMsg = err
            errorCb()
          }
        })
    },
  },
  ws: {
    private: {
      CompleteAction(e) {
        const events = ['Service.Link.GameBonus', 'Service.Unlink.GameBonus']
        if (events.includes(e.name)) {
          this.fetchData()
        }
      }
    }
  }
}
</script>

<style scoped>
.warn-common {
  margin-bottom: 10px;
}
.mt20 {
  margin-top: 20px;
}
h1.main-title {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
table {
  width: 100%;
}
.sub-title {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  color: #555555;
}
.body-text {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
}
ul {
  padding: 0px 0 8px 15px;
}
.wargaming, .forgame, .mygame {
  background-color: #F7F7F7;
  border: 1px #bbbbbb solid;
  padding: 5px 15px;
}
.action-button {
  width: 460px;
}
.action-button button {
  float: right;
}
.action-button .link-account, .action-button .activate-option {
  display: inline-block;
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  width: 220px;
  text-align: center;
  border: 0;
  cursor: pointer;
}

.link-account {
  background-color: #6C4099;
  margin-left: 15px;
  width: 200px;
  height: 35px;
}

.activate-option {
  background-color: #6C4099;
  width: 200px;
  height: 35px;
}

.dear-abonent {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 20px;
  font-style: italic;
  color: #aaaaaa;
  text-align: center;
  margin: 25px 0 10px 0;
}

.button-help {
  margin: 20px 0 10px 0;
}

.non-active, .connect-tariff:disabled, .link-account:disabled, .activate-option:disabled {
  color: #dddddd !important;
  background-color: #888888 !important;
  cursor: default !important;
}

.bold {
  font-weight: bold;
}

.connect-tariff-wrp {
  margin: 25px 0;
  text-align: center;
}

.connect-tariff {
  display: inline-block;
  margin: 0 auto;
  padding: 15px 25px;
  background-color: #6C4099;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.option-text {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 13px;
  font-weight: 500;
}
.option-text a {
  font-size: 13px;
}
.option-text a:hover {
  border-bottom: 1px dashed #333;
}
.active-option {
  font-size: 13px;
  color: #468C00;
  font-weight: 500;
  /*line-height: 25px;*/
}
.pending-option {
  font-size: 13px;
  color: #ff9933;
  font-weight: 500;
  /*line-height: 25px;*/
}
.deactivate-option {
  font-size: 13px;
  color: #999999;
  font-weight: 500;
  /*line-height: 25px;*/
}
.block-actions-time-counter {
  text-align: center;
  margin: 25px 0;
  font-size: 16px;
  border: 1px solid #bbbbbb;
  padding: 20px 0;
  color: #bb2323;
}
.platform-icon {
  width: 60px;
}
ul {
  margin: 0 0 0 10px;
}
a.tdu {
  text-decoration: underline;
}
a:visited {font-family: Tahoma, Arial, sans-serif !important;}
</style>
