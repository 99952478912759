<template>
  <div class="pay-frame" v-if="iframeUrl">
    <iframe class="pay-frame" @load="frameLoaded = true" :src="iframeUrl" v-show="frameLoaded" />
    <div class="preloader" v-if="!frameLoaded">
      <loading-spinner />
    </div>
  </div>
  <div v-else>
    <div class="pay_cap">
      Оплата картами VISA/MasterCard/Maestro
    </div>
    <div class="paydiv">
      <div>
        <div style="float: right;">
          <img src="@/assets/img/payment-icon.fw.png"/>
        </div>
        Оплата картами VISA/MasterCard/МИР. Зачисление средств на счет от нескольких минут до 3 банковских дней.
      </div>
      <br/><br/>
      <form @submit.prevent="doCardPayment">
        <table border="0" cellspacing="0" cellpadding="0" class="window">
          <tr>
            <th width="140px">Сумма:</th>
            <td width="140px">
              <input type="text" class="text number" name="amount" maxlength="8" v-model="cardSumma" required />
            </td>
            <td class="w-button" rowspan="2">
              <loading-btn name="pay_once" class="submit" :loading="cardLoading">Пополнить</loading-btn>
            </td>
            <td class="w-button" rowspan="2" v-if="$auth.check('ROLE_USE_ESPP')">
              <router-link :to="{name: 'bankcard-autopay'}" custom v-slot="{ navigate }">
                <input type="button" @click="navigate" value="Настроить автопополнение" />
              </router-link>
            </td>
          </tr>
          <tr>
            <th title="Укажите адрес электронной почты для отправки электронного чека">E-mail для чека:</th>
            <td><input type="email" class="text" name="email" maxlength="40" v-model="cardEmail" required /></td>
          </tr>
        </table>
      </form>
    </div>

    <template v-if="$auth.check('ROLE_ATK')">
<!--
      <div class="pay_cap">
        Оплата через электронный кошелек
      </div>
      <div class="paydiv">
        <div>
            Оплатить через электронный кошелек Qiwi или WebMoney без комиссии просто!<br/>
            Необходимо ввести реквизиты - номер Лицевого счета (6-значный номер),
            выбрать способ оплаты (один из ваших счетов, на котором есть деньги), ниже указать сумму и нажать кнопку "Оплатить".
        </div>
        <a href="https://qiwi.com/payment/form.action?provider=33250" class="wallet qiwi">Пополнить</a>
        <a href="https://telepay.webmoney.ru/ru/operator/864" class="wallet wm">Пополнить</a>
      </div>
-->
      <div class="pay_cap">
        Мобильный платеж (с комиссией оператора связи)
      </div>
      <div class="paydiv">
        <div>
          Данная услуга позволяет Вам пополнить свой лицевой счет «АльянсТелеком» со счета Вашего мобильного
          телефона (только номера сети сотовой связи <strong>Билайн</strong>, <strong>МТС</strong>, <strong>Мегафон</strong> и <strong>ТЕЛЕ2</strong>).
          Введите в верхнее поле номер телефона, с которого Вы хотите перевести деньги. В нижнее поле введите сумму перевода.
          Нажмите «Пополнить». Зачисление средств на счет от нескольких минут до 3 банковских дней.<br />
        </div>
        <form @submit.prevent="doMobilePayment">
          <table border="0" cellspacing="0" cellpadding="0" class="window">
            <tr>
              <th width="140px">Телефонный номер:</th>
              <td width="140px">
                <input type="text" class="text number" name="number" id="mobile_number" maxlength="10" v-model="mobilePhone" required />
              </td>
              <td class="w-button" rowspan="2">
                <loading-btn :loading="mobileLoading">Пополнить</loading-btn>
              </td>
            </tr>
            <tr>
              <th>Сумма:</th>
              <td>
                <input type="text" class="text number" name="amount" id="mobile_amount" maxlength="16" v-model="mobileSumma" required />
              </td>
            </tr>
          </table>
        </form>
      </div>
    </template>

  </div>
</template>

<script>
import api from '@/api'
import LoadingBtn from '../components/global/LoadingBtn.vue'

export default {
  components: { LoadingBtn },
  data() {
    return {
      cardSumma: null,
      cardEmail: '',
      cardLoading: false,
      mobilePhone: '',
      mobileSumma: null,
      mobileLoading: false,
      iframeUrl: '',
      frameLoaded: false,
    }
  },
  created() {
    this.$store.dispatch('FETCH_CONTACTS').then(c => {
      if (!this.cardEmail.length) {
        this.cardEmail = c.email
      }
      if (!this.mobilePhone.length) {
        this.mobilePhone = c.mobile
      }
    })
  },
  methods: {
    doCardPayment() {
      this.cardLoading = true
      api.paymentCard(this.cardSumma, this.cardEmail)
        .then(data => {
          if (data.pay_url) {
            if (data.iframe) {
              this.iframeUrl = data.pay_url
            } else {
              location = data.pay_url
            }
          }
        })
        .catch(() => this.cardLoading = false)
    },
    doMobilePayment() {
      this.mobileLoading = true
      api.paymentMobile(this.mobileSumma, this.mobilePhone)
        .catch(() => {})
        .then(() => this.mobileLoading = false)
    },
  }
}
</script>

<style scoped>
.pay-frame {
  position: relative;
  width: 100%;
  height: 600px;
  border: 0;
}
.wallet {
  text-decoration: none;
  display: inline-block;
  background-position: 0 0;
  height: 30px;
  width: 70px;
  background-repeat: no-repeat;
  padding: 15px 0 0 90px;
  border: 1px solid;
}
.wallet.qiwi {
  background-image: url('../assets/img/qiwi.png');
  background-size: 80px;
}
.wallet.wm {
  background-image: url('../assets/img/wm.png');
  background-size: 50px;
  margin: 0 50px;
  padding-left: 60px;
}
</style>
