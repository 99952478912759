<template>
  <div class="option">
    <div class="pad">
      <img class="kss_poster" :src="software.img" style="width: 110px;" />
      <div class="program_name">
        {{ software.name }}
      </div>
      <div class="description">
        <div v-html="software.description.short" />
        <br />
        <router-link v-if="software.description.full" :to="{name: 'software', params: {detailsId: software.id}}">
          Подробнее <span class="ra">&rarr;</span>
        </router-link>
      </div>
    </div>
    <table class="option_bar" style="width: 100%">
      <tr>
        <th class="price" style="width: 200px">
          <span>{{ software.price }}</span> руб.
          {{ software.cost_type === 'monthly' ? 'в месяц' : software.cost_type === 'daily' ? 'в день' : '' }}
        </th>
        <td>
          <span style="font-weight: bold; color: darkred" v-if="software.is_linked">Подписка оформлена</span>
          <img @click="$emit('show-confirm', software.id)" src="@/assets/img/button_subscr.png" style="cursor: pointer;" alt="Оформить подписку" v-else/>
        </td>
        <td class="download">
          <a v-if="software.download_url" :href="software.download_url" target="_blank">&nbsp;</a>
        </td>
        <td class="br">&nbsp;</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ['software'],
}
</script>

<style scoped>
.program_name {font: bold 18px  Arial; color:#C0240C;margin-bottom:3px}
.kss_poster{float: right; margin-left:20px;}
.description{text-align: justify}
.freebie{color:#ff9944;font-size:13px; margin: 5px}

.option{border:1px dashed gray;margin-bottom:10px}
.option .pad{margin: 10px 10px 0 10px; min-height: 146px;}
.option a{font: 12px Arial;color: #2200c1;border:0}
.option a:hover{text-decoration: underline}
.option a:visited{color: #551a8b}
.option_bar {background: url("../assets/img/win2_buy_c.gif") repeat-x scroll center top transparent;height:70px;border-collapse:collapse; border:0;margin-top: 10px}
.option_bar .bl{width:20px}
.option_bar .br{width:20px}
.option_bar .quantity{width: 70px}
.option_bar .button{width: 154px;text-align: right}
.option_bar .price{width: 140px;text-align: right;color: #B10000;font:bold 16px arial;}
.option_bar .download {width: 72px}
.option_bar .download a{background: url("../assets/img/button_download.png") no-repeat scroll right top transparent;width:60px;height:26px;display: block;float:right;text-decoration: none}
.option_bar select,.option_bar .quantity span{font: 14px Verdana, Tahoma, Arial}
</style>
