<template>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 20px; background: #f4f4f4; border-bottom: 1px dotted #bababa; padding: 5px 15px; color: #fd992c;">
      <strong>Переход в состояние добровольной блокировки</strong>
    </div>
    <div class="col-sm-12" style="line-height: 25px; margin-top: 20px; padding: 0 50px">
      <img src="@/assets/img/lock.png" style="float: right; margin: 5px; width: 100px">
      <p style="font-weight: bold" v-if="blockInfo.is_vol">
        В настоящий момент у вас <span style="color: green">активирована</span> добровольная блокировка.
      </p>
      <p style="font-weight: bold" v-else>
        В настоящий момент добровольная блокировка <span style="color: red">не активирована</span>.
      </p>
      <br/>
      <p>
        Добровольная блокировка подключается для приостановки доступа в Интернет и к сервису Wink, на срок от 10 до 181 дней.
        181 день – максимально возможная длительность «Добровольной блокировки в календарном году.
        Повторно блокировка может быть установлена не ранее чем через 30 дней с момента снятия предыдущей добровольной блокировки.
        В период добровольной блокировки абонентская плата за предоставление услуги "Интернет" не списывается.
      </p>
      <br/>
      <p>
        Если Ваш тариф пакетный (присутствует услуга КТВ), то абонентская плата за использование КТВ продолжит списываться в размере 300 рублей в месяц.
      </p>
      <br/>
      <p>
        Разблокировка выполняется по звонку в информационную службу, при личном визите в абонентский отдел или самостоятельно в личном кабинете.
      </p>
      <br/>

      <p>
        При досрочном отключении блокировки (менее, чем через 10 дней), абонентская плата списывается
        в размере 20 руб./сутки за период нахождения
        в отмененной блокировке.
      </p>
      <br/>
      <h3 v-if="blockInfo.max_days">
        У вас осталось {{ blockInfo.max_days }} дн. блокировки
      </h3>
      <br/>

      <div style="text-align: center; font-size: 14px" v-if="blockInfo.is_vol && blockPendingDate">
        Разблокировка в процессе
      </div>
      <div style="text-align: center; font-size: 14px" v-else>
        <p v-if="hasUnblockPenalty">
          Вы провели в блокировке лишь {{ blockDuration }}<br/>
          За досрочное прекращение добровольной блокировки <strong>с вас будет списано {{ blockInfo.unblock_penalty }} руб.</strong>
        </p>
        <label style="font-weight: bold">
          <input type="checkbox" id="agree" name="agree" v-model="agree">
          {{ blockInfo.is_vol ? 'Да, снять блокировку' : 'Я согласен с условиями добровольной блокировки' }}
        </label>
        <br/><br/>
        <div v-if="!blockInfo.is_vol && blockPendingDate">
          У вас запланирована блокировка на {{ blockPendingDate }}
          <br/><br/>
        </div>
        <div v-else-if="!blockInfo.is_vol && minStartDate">
          С даты
          <date-picker class="datepicker-wrapper" v-model="dateFrom" :disabled-dates="{to: minStartDate, from: maxStartDate}"
            :language="ruPicker" format="dd.MM.yyyy" :disabled="!agree" monday-first
          />
          по
          <date-picker class="datepicker-wrapper" v-model="dateTo" :disabled-dates="{to: minExpireDate, from: maxExpireDate}"
            :language="ruPicker" format="dd.MM.yyyy" :disabled="!agree" monday-first
          />
          &nbsp;
          <b>{{ selectedBlockDays }} дн.</b>
          <br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
        <loading-btn :disabled="!agree" :loading="loading" @click="blockToggle">
          {{ blockInfo.is_vol ? 'Снять' : (blockPendingDate ? 'Отменить' : 'Установить') }} блокировку
        </loading-btn>
        <br/><br/>
        <div class="validation-error">
          {{ errorMsg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import DatePicker from 'vuejs-datepicker'
import ruPicker from 'vuejs-datepicker/dist/locale/translations/ru'
import api from '@/api'

let updateUserInterval

export default {
  components: { DatePicker },
  data() {
    const bi = this.$user().block_info || {}
    const dateFrom = new Date((bi.min_date || 0) * 1000)
    const dateTo = new Date(dateFrom.getTime() + Math.min(bi.max_days || 0, 30) * 86400 * 1000)
    return {
      agree: false,
      loading: false,
      errorMsg: '',
      dateFrom,
      dateTo,
      ruPicker,
    }
  },
  computed: {
    hasUnblockPenalty() {
      const user = this.$user()
      return user.block_info.is_vol && user.block_info.start_date > Date.now() / 1000 - 10 * 86400
    },
    blockDuration() {
      const blockTime = Math.floor(Date.now() / 1000) - this.blockInfo.start_date
      const parts = []
      const d = Math.floor(blockTime / 86400)
      if (d) {
        parts.push(d + ' дн.')
      }
      const h = Math.floor((blockTime % 86400) / 3600)
      if (h) {
        parts.push(h + ' ч.')
      }
      const m = Math.floor((blockTime % 3600) / 60)
      if (m) {
        parts.push(m + ' мин.')
      }
      if (parts.length === 0) {
        parts.push((blockTime % 60) + ' сек.')
      }
      return parts.join(' ')
    },
    selectedBlockDays() {
      return Math.ceil((this.dateTo - this.dateFrom) / 86400000)
    },
    blockInfo() {
      return this.$user().block_info || {}
    },
    blockPendingDate() {
      const pendingDate = this.blockInfo.pending_date
      if (pendingDate) {
        return dateFormat(pendingDate * 1000, 'dd mmmm yyyy')
      }
      return null
    },
    minStartDate() {
      const minDate = this.blockInfo.min_date
      if (minDate) {
        return new Date(minDate * 1000)
      }
      return null
    },
    maxStartDate() {
      return new Date(this.dateTo.getTime() - 10 * 86400 * 1000)
    },
    minExpireDate() {
      return new Date(this.dateFrom.getTime() + 10 * 86400 * 1000)
    },
    maxExpireDate() {
      const maxDays = this.blockInfo.max_days || 0
      return new Date(this.dateFrom.getTime() + maxDays * 86400 * 1000)
    },
  },
  created() {
    this.$auth.fetch()
    updateUserInterval = setInterval(() => this.$auth.fetch(), 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(updateUserInterval)
  },
  methods: {
    blockToggle() {
      this.loading = true
      this.errorMsg = ''
      api.blockToggle(this.dateFrom, this.dateTo)
        .then(res => {
          if (res.success) {
            this.agree = false
            return this.$auth.fetch().catch(() => {})
          } else {
            this.errorMsg = res.message
          }
        })
        .catch(() => {
          this.errorMsg = 'Извините, произошла системная ошибка, попробуйте позже'
        })
        .then(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
.datepicker-wrapper {
  display: inline-block;
}
</style>
