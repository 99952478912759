<script>
export default {
  render: h => h(),
  ws: {
    private: {
      StartAction(e) {
        this.$store.commit('START_ACTIVE_ACTION', e.name)
      },
      CompleteAction(e) {
        this.$auth.fetch().then(() => {
          this.$store.commit('COMPLETE_ACTIVE_ACTION', e.name)
        })
      },
    }
  }
}
</script>
