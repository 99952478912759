<template>
  <div>
    <div style="display: flex;">
      <button v-for="title, btnType in buttons" :key="btnType"
        :disabled="loading"
        :class="{active: activeType === btnType}"
        @click="makeRequest(btnType)"
      >
        {{ title }}
      </button>
    </div>

    <pre style="margin-top: 20px; overflow: scroll;">{{ output }}</pre>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      loading: false,
      output: '',
      activeType: '',
      buttons: {
        'avail-opts': 'Доступные опции',
        'linked-opts': 'Подключенные услуги',
        'svc-opts': 'Подключенные опции',
        'self-block': 'Инфо по блокировке',
        'other-info': 'Прочее',
      }
    }
  },
  methods: {
    makeRequest(type) {
      this.output = ''
      this.loading = true
      this.activeType = type
      axios.post('/elk/debug', {type})
        .then((res) => this.output = res.data)
        .finally(() => this.loading = false)
    }
  },
}
</script>

<style scoped>
.active {
  text-decoration: underline;
}
</style>
