import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './plugins/auth'
import ws from './plugins/ws'
import './plugins/i18n'

Vue.use(auth)
Vue.use(ws)

import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
Vue.use(VueSvgInlinePlugin)

import './plugins/ga'

import LoadingSpinner from './components/global/LoadingSpinner.vue'
import LoadingBtn from './components/global/LoadingBtn.vue'

Vue.component('LoadingSpinner', LoadingSpinner)
Vue.component('LoadingBtn', LoadingBtn)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
