<template>
  <button class="btn-loading" :class="{loading}" @click="$emit('click', $event)">
    <span class="btn-loader" v-if="loading"><loading-spinner :size="15" :width="8" /></span>
    <span class="btn-content"><slot /></span>
  </button>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style scoped>
.btn-loading {
  position: relative;
}
.btn-loading.loading {
  pointer-events: none;
}
.btn-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.loading .btn-content {
  opacity: 0;
}
</style>
