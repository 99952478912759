<template>
  <div style="text-align: center;" v-if="listLoading">
    <loading-spinner />
  </div>
  <div v-else>
    <h4>Ваши услуги</h4>
    <div class="services-connected">
      <table width="100%" border="0" cellpadding="0" cellspacing="0" class="stbl">
        <tr class="capt">
          <td width="220px">Услуга</td>
          <td>Абонентская плата</td>
          <th>Дополнительная информация</th>
          <td width="80">&nbsp;</td>
        </tr>
        <tr v-for="service in serviceList" :key="service.link_id">
          <td>{{ service.name }}</td>
          <td v-if="!service.cost && service.in_tariff && $user().tariff">{{ $user().tariff.title }}</td>
          <td v-else>
            <span v-if="service.is_blocked">в блокировке</span>
            <span v-else-if="!service.cost">бесплатно</span>
            <span v-else>
              {{ service.cost }}
              руб{{ service.cost_type === 'monthly' ? '/месяц' : service.cost_type === 'daily' ? '/день' : '' }}
            </span>
          </td>
          <th v-html="service.comment" />
          <td>
            <a href="javascript:;" v-if="service.unlinkable && !unlinkingId && !$store.state.activeActions.length" @click="serviceUnlink(service.link_id)">отключить</a>
            <span v-if="unlinkingId && unlinkingId == service.link_id">отключается</span>
          </td>
        </tr>
      </table>
    </div>

    <div class="warn-common">
      <span class="warn-message warn-message-error" v-if="errorMsg">
        Ошибка:
        <span>{{ errorMsg }}</span>
      </span>
    </div>

    <template v-if="$auth.check(['CAN_WHITE_IP', 'CAN_CHANGE_BLOCK', 'CAN_SOCIAL_INET'])">
      <h4>Подключение услуг</h4>
      <table width="100%" border="0" cellspacing="0" cellpadding="0" class="add_service">
        <tr class="capt">
          <td width="220">Услуга</td>
          <td>Абонентская плата</td>
          <th>Стоимость подключения</th>
          <td width="80">&nbsp;</td>
        </tr>

        <template v-if="$auth.check('CAN_WHITE_IP')">
          <tr class="emt">
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr class="caption">
            <td class="n">Выделенный IP адрес</td>
            <td>50 руб/месяц</td>
            <td>бесплатно</td>
            <th>описание</th>
          </tr>
          <tr class="info">
            <td colspan="4">
              <b>Услуга "Выделенный IP"</b> – позволяет организовать виртуальный защищенный канал и построить локальную сеть.
              Существуют сайты и сервисы, которые в обязательном порядке требуют «белый» IP.
              В некоторых онлайн играх «белый» IP может улучшить качество связи.
              <br/>Абонентская плата списывается ежедневно, равными долями, пропорционально количеству дней в текущем месяце.
              <br/>Как отключить услугу?<br/>
              <ul>
                <li>в личном кабинете по истечении 30 дней от даты её подключения;</li>
                <li>при нахождении счета в блокировке более 30 дней услуга автоматически отключается.</li>
              </ul>
            </td>
          </tr>
          <tr class="link">
            <td colspan="3">
              <table border="0" cellspacing="0" cellpadding="0" align="right">
                <tr v-if="!isWhiteIpLinked && !whiteIpToggling">
                  <td width="22">
                    <input type="checkbox" id="agree_white_ip" v-model="whiteIpAgree" />
                  </td>
                  <td>
                    <label for="agree_white_ip">c данной услугой ознакомлен, с условиями согласен</label>
                  </td>
                </tr>
                <tr v-if="whiteIpToggling">
                  <td colspan="2">
                    Операция {{ isWhiteIpLinked ? 'отключения' : 'подключения' }} услуги в процессе, ожидайте завершения.
                  </td>
                </tr>
              </table>
            </td>
            <th>
              <loading-btn :disabled="!isWhiteIpLinked && !whiteIpAgree" :key="isWhiteIpLinked" :loading="whiteIpToggling" @click="whiteIpToggle">
                {{ isWhiteIpLinked ? 'Отключить' : 'Подключить' }}
              </loading-btn>
            </th>
          </tr>
        </template>

        <template v-if="$auth.check('CAN_CHANGE_BLOCK') && !$user().is_blocked">
          <tr class="emt">
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr class="caption">
            <td class="n">Добровольная блокировка</td>
            <td>бесплатно</td>
            <td>бесплатно</td>
            <th>описание</th>
          </tr>
          <tr class="info">
            <td colspan="4">
              <img src="@/assets/img/lock.png" style="float: right; margin: 5px; width: 100px">
              <p>
                Добровольная блокировка подключается для приостановки доступа в Интернет и к сервису Wink, на срок от 10 до 181 дней.
                181 день – максимально возможная длительность «Добровольной блокировки в календарном году.
                Повторно блокировка может быть установлена не ранее чем через 30 дней с момента снятия предыдущей добровольной блокировки.
                В период добровольной блокировки абонентская плата за предоставление услуги "Интернет" не списывается.
              </p>
              <br />
              <p>
                Если Ваш тариф пакетный (присутствует услуга КТВ), то абонентская плата за использование КТВ продолжит списываться в размере 300 рублей в месяц.
              </p>
            </td>
          </tr>
          <tr class="link">
            <td></td>
            <th colspan="3" style="text-align: right;">
              <router-link :to="{name: 'block'}" custom v-slot="{ navigate }">
                <input type="button" value="Подробнее" @click="navigate" />
              </router-link>
            </th>
          </tr>
        </template>

        <template v-if="$auth.check('CAN_SOCIAL_INET')">
          <tr class="emt">
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr class="caption">
            <td class="n">Услуга «Социальный интернет»</td>
            <td>бесплатно</td>
            <td>бесплатно</td>
            <th>описание</th>
          </tr>
          <tr class="info">
            <td colspan="4">
              При подключении опции будет предоставлен только бесплатный доступ к социальным ресурсам. Остальные услуги будут приостановлены.
            </td>
          </tr>
          <tr class="link">
            <td></td>
            <th colspan="3" style="text-align: right;">
              <router-link :to="{name: 'social-inet'}" custom v-slot="{ navigate }">
                <input type="button" value="Подробнее" @click="navigate" />
              </router-link>
            </th>
          </tr>
        </template>

        <tr class="emt">
          <td colspan="4"></td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      listLoading: true,
      serviceList: [],
      whiteIpAgree: false,
      unlinkingId: null,
      errorMsg: '',
    }
  },
  computed: {
    isWhiteIpLinked() {
      return (this.$user().inet || {}).is_white
    },
    whiteIpToggling() {
      return this.$store.getters.hasActiveAction(['Service.Link.WhiteIp', 'Service.Unlink.WhiteIp'])
    },
  },
  created() {
    this.updateList()
  },
  methods: {
    updateList() {
      return api.serviceList().then(data => {
        this.serviceList = data
        this.listLoading = false
      })
    },
    whiteIpToggle() {
      this.errorMsg = ''
      const actName = this.isWhiteIpLinked ? 'Service.Unlink.WhiteIp' : 'Service.Link.WhiteIp'
      this.$store.commit('START_ACTIVE_ACTION', actName)
      api.whiteIpToggle()
        .catch(() => ({message: 'Произошла ошибка на сервере'}))
        .then(res => {
          if (res.success) {
            return
          }
          this.errorMsg = res.message
          this.$store.commit('COMPLETE_ACTIVE_ACTION', actName)
        })
    },
    serviceUnlink(linkId) {
      if (!confirm('Вы действительно хотите отключить данную услугу ?')) {
        return
      }
      this.unlinkingId = linkId
      this.errorMsg = ''
      api.serviceUnlink(linkId)
        .catch(() => ({message: 'Произошла ошибка на сервере'}))
        .then(res => {
          if (res.success) {
            return
          }
          this.unlinkingId = null
          this.errorMsg = res.message
        })
    }
  },
  ws: {
    private: {
      CompleteAction() {
        if (this.updateList) {
          this.updateList().then(() => this.unlinkingId = null)
        }
      }
    }
  }
}
</script>
