<template>
  <div>
    <div v-if="!$auth.check('ROLE_ABONENT')" style="margin:20px 35px;padding:20px 30px;color:#200;border:1px dashed #500">
      <strong style="color:#a00">Внимание!</strong>
      <br /><br />
      Ваш IP адрес, не совпадает с IP адресом в нашей биллинговой системе. Возможно, Вы сменили сетевое оборудование.
      Вам необходимо зарегистрировать его, для этого зайдите в раздел (<router-link :to="{name: 'parameters'}">Настройка</router-link>),
      либо позвонить по телефону технической поддержки 8(423)205-01-01.
      <br /><br />
      <div style="text-align:right"><strong>Баланс счёта: {{ Number($user().balance).toFixed(2) }} руб</strong></div>
    </div>

    <div style="text-align: center" v-if="loading || changing">
      <loading-spinner />
    </div>
    <div v-else>
      <div class="warn-common" style="margin-bottom: 10px;">
        <span class="warn-message warn-message-info" v-if="successMsg">
          <span v-html="successMsg" />
        </span>
        <span class="warn-message warn-message-error" v-if="errorMsg">
          Ошибка:
          <span>{{ errorMsg }}</span>
        </span>
      </div>

      <div v-if="instantTariffs.length && $auth.check('ROLE_ABONENT')">
        <div class="title title-left">
          Быстрая смена тарифа
        </div>
        <table width="840px" border="0" cellspacing="0" cellpadding="0" style="border:1px solid #999;margin:0 0 10px 15px">
          <tr>
            <td style="padding:5px;text-align:left">
              <label v-for="t in instantTariffs" :key="t.id">
                <input type="radio" name="fast_change" :value="t.id" v-model="newTariffInstant" />
                <strong>{{ t.name }}</strong>
                <br/>
              </label>
            </td>
            <td style="padding:10px 0px;text-align:left">
              - Быстрая смена тарифа возможна только на более быстрый безлимитный тариф
              <br/>
              <br/>
              - Новый тариф вступает в силу через 15 минут после смены
              <br/>
              <br/>
              - Выберите тариф и нажмите кнопку
              <button :disabled="!newTariffInstant" @click="setCurrentTariff">Сменить тариф</button>
              <br />
            </td>
          </tr>
        </table>
      </div>

      <div v-if="nextTariffs.length && $auth.check('ROLE_ABONENT')">
        <div class="title title-left">
          Смена тарифа в следующем расчётном периоде
        </div>
        <table width="840px" border="0" cellspacing="1" cellpadding="0" class="tbl_tc" style="margin-left:15px">
          <thead>
            <tr>
              <th width="400px">
                <u>Текущий</u> расчётный период
              </th>
              <th>
                <u>Следующий</u> расчётный период
              </th>
            </tr>
          </thead>
          <tr>
            <td>
              <strong>c</strong> {{ periods.current.start }}
              <strong>по</strong> {{ periods.current.end }}
            </td>
            <td>
              <strong>c</strong> {{ periods.next.start }}
              <strong>по</strong> {{ periods.next.end }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Текущий тариф:</strong>
              <input class="cur_tp" type="text" :value="$user().tariff.title" readonly style="margin: 0 3px" />
            </td>
            <td>
              <strong>Следующий тариф:</strong>
              <select name="next_tp" v-model="newTariff" style="margin: 0 3px">
                <option v-for="t in nextTariffs" :key="t.id" :value="t.id">
                  {{ t.name }}
                </option>
              </select>
              <input :disabled="!newTariff" type="submit" value="НАЗНАЧИТЬ" @click="setNextTariff" />
            </td>
          </tr>
        </table>
      </div>
      <br/>


      <div v-if="tariffs.length && $auth.check('ROLE_ABONENT')">
        <div class="tp_group">
          <span>Доступные тарифы</span>
        </div>
        <table border="0" cellspacing="1" cellpadding="0" class="tbl_tl">
          <thead>
            <tr>
              <th>Тарифный план</th>
              <td>Абонентская плата</td>
              <td>Скорость передачи<br />интернет трафика, до</td>
              <td>Скорость передачи<br />локального трафика, до</td>
              <td width="100">&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="t in tariffs" :key="t.id">
              <th>{{ t.name }}</th>
              <td>{{ t.cost }} руб</td>
              <td>{{ Math.floor(t.speed / 1024) }} Мегабит/сек</td>
              <td>100 Мегабит/сек</td>
              <td>
                <button v-if="t.id != nextTariffId" @click="newTariff = t.id; setNextTariff()">Перейти</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import api from '@/api'

export default {
  data() {
    return {
      loading: false,
      tariffs: [],
      tariffId: null,
      nextTariffId: null,
      newTariffInstant: null,
      newTariff: null,
      successMsg: '',
      errorMsg: '',
    }
  },
  computed: {
    changing() {
      return this.$store.getters.hasActiveAction(['Tariff.Link', 'Tariff.Unlink', 'Tariff.NextTariff'])
    },
    instantTariffs() {
      return this.tariffs.filter(t => t.can_instant && t.id != this.tariffId)
    },
    nextTariffs() {
      return this.tariffs.filter(t => t.id != this.nextTariffId)
    },
    periods() {
      const curYear = new Date().getFullYear()
      const curMonth = new Date().getMonth()
      return {
        current: {
          start: dateFormat(new Date(curYear, curMonth, 1), 'd mmmm yyyy, 00:00'),
          end: dateFormat(new Date(curYear, curMonth + 1, 1), 'd mmmm yyyy, 00:00'),
        },
        next: {
          start: dateFormat(new Date(curYear, curMonth + 1, 1), 'd mmmm yyyy, 00:00'),
          end: dateFormat(new Date(curYear, curMonth + 2, 1), 'd mmmm yyyy, 00:00'),
        },
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.errorMsg = ''
      this.loading = true
      api.tariffsAvailForChange()
        .then(data => {
          if (data.error) {
            return this.errorMsg = data.error
          }
          this.tariffs = data.tariffs || []
          this.tariffId = data.tariff_id
          this.nextTariffId = data.next_tariff_id
        })
        .catch(() => this.errorMsg = 'Ошибка сервера')
        .then(() => this.loading = false)
    },
    setNextTariff() {
      this.successMsg = ''
      this.errorMsg = ''
      this.$store.commit('START_ACTIVE_ACTION', 'Tariff.NextTariff')
      api.tariffSetNext(this.newTariff)
        .then(res => {
          if (!res.success) {
            return res.message
          }
          this.successMsg = 'Тариф на следующий месяц успешно установлен'
        })
        .catch(() => 'Ошибка сервера')
        .then(err => {
          this.newTariff = null
          if (err) {
            this.errorMsg = err
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Tariff.NextTariff')
          }
        })
    },
    setCurrentTariff() {
      this.successMsg = ''
      this.errorMsg = ''
      this.$store.commit('START_ACTIVE_ACTION', 'Tariff.Link')
      api.tariffSetCurrent(this.newTariffInstant)
        .then(res => {
          if (!res.success) {
            return res.message
          }
          this.successMsg = 'Тариф успешно изменен'
        })
        .catch(() => 'Ошибка сервера')
        .then(err => {
          this.newTariffInstant = null
          if (err) {
            this.errorMsg = err
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Tariff.Link')
          }
        })
    },
  },
  ws: {
    private: {
      CompleteAction(e) {
        const events = ['Tariff.Link', 'Tariff.NextTariff']
        if (events.includes(e.name)) {
          this.fetchData()
        }
      }
    }
  }
}
</script>

<style>

</style>
