<template>
  <div>
    <loading-btn style="padding: 20px" :loading="activateInetLoading" @click="activateInet">
      Активировать интернет
    </loading-btn>
    <div class="validation-error">
      {{ activationError }}
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      activationError: '',
    }
  },
  computed: {
    activateInetLoading() {
      return this.$store.getters.hasActiveAction('Service.Link.Inet')
    }
  },
  methods: {
    activateInet() {
      if (!confirm('После этого действия у вас начнет списываться абонентская плата за интернет согласно вашему тарифному плану.\n\nПродолжить?')) {
        return
      }
      this.activationError = ''
      this.$store.commit('START_ACTIVE_ACTION', 'Service.Link.Inet')
      api.activateInet()
        .then(res => {
          if (!res.success) {
            this.activationError = res.message
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Service.Link.Inet')
            return
          }
        }).catch(e => {
          this.activationError = e.response.data.message
          this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Service.Link.Inet')
        })
    }
  }
}
</script>

<style>

</style>
