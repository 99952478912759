import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    forceReload: false,
    activeActions: [],
    reportTypes: [],
    contacts: {
      email: '',
      phone: '',
      mobile: '',
      notify: false,
      fetched: false,
    },
  },
  getters: {
    hasActiveAction: state => name => {
      if (name.some) {
        return name.some(n => state.activeActions.findIndex(a => a.name === n) >= 0)
      }
      return state.activeActions.findIndex(a => a.name === name) >= 0
    }
  },
  mutations: {
    PENDING_RELOAD(state) {
      state.forceReload = true
    },
    SET_CONTACTS(state, c) {
      Object.assign(state.contacts, c)
    },
    SET_ACTIVE_ACTIONS(state, actions) {
      state.activeActions = actions
    },
    START_ACTIVE_ACTION(state, name) {
      const idx = state.activeActions.findIndex(a => a.name === name)
      if (idx === -1) {
        state.activeActions.push({name, timestamp: Math.round(Date.now() / 1000)})
      }
    },
    COMPLETE_ACTIVE_ACTION(state, name) {
      const idx = state.activeActions.findIndex(a => a.name === name)
      state.activeActions.splice(idx, 1)
    },
    CLEAR(state) {
      state.contacts.fetched = false
      state.reportTypes = []
    },
  },
  actions: {
    FETCH_CONTACTS({ state, commit }, force) {
      if (!state.contacts.fetched || force) {
        return api.getUserContacts()
          .then(c => commit('SET_CONTACTS', c))
          .then(() => {
            state.contacts.fetched = true
            return state.contacts
          })
      }
      return new Promise(resolve => resolve(state.contacts))
    },
    FETCH_REPORT_TYPES({ state }) {
      if (state.reportTypes.length === 0) {
        return api.getReportTypes().then(types => state.reportTypes = types)
      }
      return new Promise(resolve => resolve(state.reportTypes))
    }
  }
})
