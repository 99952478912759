<template>
  <multiselect
    :placeholder="label"
    v-model="val"
    @input="$emit('input', val)"
    :options="items"
    :allowEmpty="false"
    :showLabels="false"
    :loading="loading"
  >
    <template v-slot:noResult>
      {{ noResult }}
    </template>
    <template v-slot:noOptions>
      {{ noOptions }}
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: ['label', 'value', 'loading', 'items', 'no-result', 'no-options'],
  components: { Multiselect },
  data() {
    return {
      val: this.value
    }
  },
  watch: {
    value(val) {
      this.val = val
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
