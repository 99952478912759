<template>
  <div id="app">
    <div v-if="$auth.ready()">
      <auth-watcher v-if="$auth.check()"/>
      <router-view/>
    </div>
    <div class="preloader" v-else>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import AuthWatcher from '@/components/AuthWatcher'

export default {
  components: { AuthWatcher },
  ws: {
    public: {
      AppUpdate() {
        this.$store.commit('PENDING_RELOAD')
      }
    }
  },
}
</script>

<style>
@import './assets/styles/common.css';

#app {
  font: normal 12px Verdana, Tahoma, Arial, Helvetica, sans-serif;
  color: #333;
}
#app .preloader {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
</style>
