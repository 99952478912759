import axios from 'axios'
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import authHttp from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import authRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import router from '@/router'
import store from '@/store'

export default {
  install(Vue) {
    Vue.use(VueAuth, {
      plugins: {
        http: axios,
        router,
      },
      drivers: {
        http: authHttp,
        router: authRouter,
        auth: {
          request: function (req, token) {
            this.drivers.http.setHeaders.call(this, req, {Authorization: 'Bearer ' + token})
          },
          response: function (res) {
            const resData = res.data || {}
            return resData.token
          }
        },
      },
      options: {
        parseUserData(data) {
          store.commit('SET_ACTIVE_ACTIONS', data.active_actions || {})
          return data.user || {}
        },
        fetchData: {url: '/user/me'},
        refreshData: {interval: 25},
        logoutData: {redirect: '/login'}
      }
    })
    Vue.prototype.$user = function () {
      return Vue.auth.user() || {}
    }
  }
}
