import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from '@/router'

Vue.use(VueAnalytics, {
  id: 'UA-65763649-1',
  router,
  ignoreRoutes: ['login'],
  autoTracking: {
    pageviewTemplate(route) {
      const user = Vue.auth.user() || {}
      const result = {
        page: route.path,
        location: window.location.href,
        dimension2: user.city,
      }
      if (user.is_new) {
        result.newAccount = 'new'
        result.dimension1 = 'New Abonent'
      }
      return result
    }
  }
})
