<template>
  <div style="padding-top: 20px">
    <div style="margin: 0px auto; width: 934px" class="main_tab">
      <div>
        <img src="@/assets/img/main_top.gif" width="934px" height="28px" alt=""/>
      </div>
      <div class="sbody" >
        <div class="content" align="center" style="width:860px;border:0px dashed #eee; margin: 0px auto; text-align: left">

          <table id="menu" style="width: 100%">
            <tr>
              <td style="width: 136px">
                <router-link to="/" class="logo">
                  <img src="@/assets/img/atk-logo-stat.png" alt="logo" />
                </router-link>
              </td>
              <td class="menu_tab" style="text-align: left">
                <span>{{ $route.meta.title }}</span>
                <br/>
                <table class="menu_line" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
                  <tr>
                    <td width="4">
                      <img width="4" height="28" src="@/assets/img/mn_l.gif" alt="'"/>
                    </td>
                    <td style="padding-left: 5px; padding-bottom: 2px;">
                      <div style="display: inline" v-for="(item, k) in menu" :key="item.path">
                        <template v-if="k > 0">&nbsp;::&nbsp;</template>
                        <router-link :to="{name: item.to}">{{ item.title }}</router-link>
                      </div>
                    </td>
                    <td style="text-align: right; padding-right: 5px; padding-bottom: 2px;">
                      [&nbsp;<a href="#" @click.prevent="$auth.logout()"><b>Выход</b></a>&nbsp;]
                    </td>
                    <td width="4">
                      <img width="4" height="28" src="@/assets/img/mn_r.gif" alt=""/>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br style="clear: both"/>

          <div>
            <div class="warn-common"></div>
            <router-view />
          </div>

        </div>
      </div>
      <div class="bottom-img">
          <img src="@/assets/img/main_dwn.gif" alt="" />
      </div>
    </div>
    <div class="copy">
      &copy; <a style="border:0px" href="http://inetvl.ru/">АльянсТелеком</a> 2007-{{ currentYear }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    menu() {
      return this.$router.options.routes[1].children
        .filter(route => route.meta && route.meta.inMenu && (route.meta.auth ? this.$auth.check(route.meta.auth) : true))
        .map(route => ({to: route.name, title: route.meta.title}))
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
  mounted() {
    document.body.style.background = '#bbb'
  },
  beforeDestroy() {
    document.body.style.background = ''
  },
}
</script>

<style scoped>
div.bottom-img img {margin-bottom: -4px;}
.copy {text-align:center;color:#444;font-size:11px;padding-bottom:15px}
.copy a,.copy a:visited {color:#444;text-decoration:none}.copy a:hover,.copy a:active {color:#444;text-decoration:underline}
.menu_line .router-link-active {
  text-decoration: underline;
}
</style>
