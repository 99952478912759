<template>
  <div>
    <h3 class="green">
      Активация промо-кода
    </h3>
    <p>Активация скидок, полученных во время проведения акций</p>
    <form class="promo" @submit.prevent="activatePromoCode">
      <input type="text" maxlength="12" name="promo_code" v-model="promoCode"/>
      &nbsp;
      <loading-btn type="submit" :disabled="promoCode.length < 6" :loading="loading">
        Активировать скидку
      </loading-btn>
      <br/>
      <div class="warn-message warn-message-info" v-if="success">Промо-код успешно активирован</div>
      <div class="warn-message warn-message-error" v-else>{{ errorMsg }}&#8291;</div>
    </form>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      success: false,
      loading: false,
      promoCode: '',
      errorMsg: '',
    }
  },
  methods: {
    activatePromoCode() {
      this.success = false
      this.loading = true
      this.errorMsg = ''
      api.promoCode(this.promoCode)
        .then(res => {
          if (res.success) {
            this.success = true
            setTimeout(() => this.$auth.fetch(), 5000)
          } else {
            this.errorMsg = res.message
          }
        })
        .catch(err => {
          const res = err.response
          if (res.status === 429) {
            let afterText = 'позже'
            const resetTime = res.headers['x-ratelimit-reset']
            if (resetTime) {
              afterText = 'через '+Math.ceil((resetTime - Date.now() / 1000) / 60)+' мин.'
            }
            this.errorMsg = 'Слишком частые запросы, попробуйте ' + afterText
          } else {
            this.errorMsg = 'Извините, произошла системная ошибка, попробуйте позже'
          }
        })
        .then(() => this.loading = false)
    }
  }
}
</script>
