<template>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
      <td>
        <div style="border:1px solid #9ba2aa;padding:1px;">
          <form name="phones" id="phones" @submit.prevent="doSave">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab0">
              <tr>
                <td valign="top">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tab1">
                    <tr>
                      <td class="caption">Настройки уведомлений :</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="info">
                        Для повышения качества обслуживания, получения оперативной информации о состоянии своего лицевого счета,
                        получения рассылок информационных SMS сообщений от нашей компании,
                        Вам необходимо указать свои действующие номера телефонов.
                        Внесенная информация будет использована для вашей авторизации при звонке в наш call-центр.
                      </td>
                    </tr>
                    <tr>
                      <td width="500px">
                        <table width="" border="0" cellspacing="0" cellpadding="0">
                          <tr v-if="needSmsVerify">
                            <td>Код подтверждения номера мобильного телефона</td>
                            <th><input v-model="smsVerifyCode" /></th>
                          </tr>

                          <tr>
                            <td colspan="2" style="font-weight: bold">Ваши телефонные номера:</td>
                          </tr>
                          <tr v-if="$auth.check('ROLE_ATK')">
                            <td>Домашний (cтационарный)</td>
                            <th>
                              <div style='float: right'>
                                <input class="pwd" :key="refresh" v-model="phone" v-mask="'+7 (423) ###-##-##'" />
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <td>Мобильный</td>
                            <th>
                              <div style='float: right'>
                                <input class="pwd" :key="refresh" v-model="mobile" v-mask="'+7 (###) ###-##-##'" />
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <td colspan="2" style="font-weight: bold">Введите ваш адрес электронной почты:</td>
                          </tr>
                          <tr>
                            <td>E-Mail</td>
                            <th>
                              <input style="width:180px;font-size:12px" v-model="email" />
                            </th>
                          </tr>

                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="font-size: 9px; font-weight: bold">
                        Я согласен на получение информационных, рекламных и иных аналогичных сообщений,
                        распространяемых Оператором связи по сети электросвязи (сети передачи данных,
                        сети телефонной связи, включая сети подвижной радиотелефонной связи), по введенным мною контактным данным
                      </td>
                    </tr>
                    <tr v-if="$auth.check('ROLE_ATK')">
                      <td width="500px">
                        <table width="" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td>
                              <label for="sms_notify">Присылать уведомления по смс</label>
                            </td>
                            <th align="right">
                              <input id="sms_notify" type="checkbox" style="float: right" v-model="notify" />
                            </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div style="text-align: center">
                          <br />
                          <br />
                          <loading-btn :loading="saving" style="width:300px;padding:1px 4px;margin:0px;font:11px Verdana, Arial, Helvetica, sans-serif">
                            Сохранить
                          </loading-btn>
                          <br />
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div style="text-align:center;padding:10px">
                    <div class="center warn-message warn-message-info" v-if="saveSuccess">
                      Данные успешно сохранены
                    </div>
                    <div class="center warn-message warn-message-error" v-else>
                      {{ saveErrorMessage }}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { mask } from 'vue-the-mask'
import api from '@/api'

export default {
  directives: { mask },
  data() {
    return {
      email: '',
      phone: '7423 ',
      mobile: '7',
      needSmsVerify: false,
      smsVerifyCode: '',
      refresh: 0,
      notify: false,
      saving: false,
      saveSuccess: false,
      saveError: false,
      saveErrorMessage: '',
    }
  },
  created() {
    this.updateContacts()
  },
  methods: {
    updateContacts(force) {
      this.$store.dispatch('FETCH_CONTACTS', force)
        .then(c => {
          this.phone = c.phone || (!force && this.phone)
          this.mobile = c.mobile || (!force && this.mobile)
          this.email = c.email
          this.notify = c.notify

          this.refresh = Date.now()
        })
    },
    doSave() {
      this.saving = true
      this.saveSuccess = false
      this.saveError = false
      this.saveErrorMessage = ''
      api.saveUserContacts(this.email, this.phone, this.mobile, this.notify, this.smsVerifyCode)
        .catch(() => ({message: 'Произошла ошибка на сервере. Повторите попытку позднее.'}))
        .then(res => {
          if (res.success) {
            this.saveSuccess = true
            this.updateContacts(true)
          } else {
            this.saveError = true
            this.saveErrorMessage = res.message
          }
          this.needSmsVerify = res.sms_verify
          this.saving = false
        })
    },
  }
}
</script>
