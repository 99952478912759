<template>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 20px; background: #f4f4f4; border-bottom: 1px dotted #bababa; padding: 5px 15px; color: #fd992c;">
      <strong>Переезжать вместе с “АльянсТелеком” выгодно!</strong>
    </div>
    <div class="col-sm-12" style="line-height: 25px; margin-top: 20px; padding-left: 15px;">
      <p>
        Чтобы продолжить пользоваться услугами от нашей компании на новом месте жительства,
        Вам достаточно заполнить простую форму, которая находится ниже, и нажать кнопку “Переехать”. <br>
        И помните, переезжая с услугами от нашей компании, Вы получаете месяц услуг в подарок!
      </p>
    </div>

    <div class="col-sm-12" style="margin-top: 20px; background: #f4f4f4; border-bottom: 1px dotted #bababa; padding: 5px 15px; color: #fd992c;">
      <strong>Заполните форму</strong>
    </div>
    <div class="col-sm-12" style="line-height: 25px; margin-top: 20px; padding-left: 15px;">
      <p>Для активации услуги “Переезд” заполните форму, расположенную ниже.</p>
      <div v-if="$auth.check('ROLE_RTK')">
        <h1 class="center">Форма для заполнения нового адреса и контактного телефона</h1>
      </div>
      <div style="padding-left: 40px;" v-else>
        <h3 style="font-size: 28px; font-weight: 500;">Куда</h3>

        <div style="float: left;" class="movement">
          <label for="city" style="text-align: right; width: 170px; margin-right: 10px;">Населенный пункт</label>
          <select v-model="city" id="city" style="width: 300px;">
            <option v-for="item in cities" :key="item" :selected="item === city">
              {{ item }}
            </option>
          </select>
          <br/>
          <label for="street" style="text-align: right; width: 170px; margin-right: 10px;">Улица</label>
          <select-search id="street" style="width: 300px; margin-bottom: 15px; display: inline-block;"
            label=""
            v-model="street"
            :items="streets"
            :loading="!cities.length"
            no-result="Улица не найдена"
            no-options="Выберите населенный пункт"
          />
          <br/>
          <label for="building" style="text-align: right; width: 170px; margin-right: 10px;">Дом</label>
          <input v-model="house" id="building" style="width: 100px;">
          <br/>
          <label for="flat" style="text-align: right; width: 170px; margin-right: 10px;">Квартира</label>
          <input v-model="flat" id="flat" style="width: 100px;">
          <br/>
          <label for="phone" style="text-align: right; width: 170px; margin-right: 10px;">Контактный телефон</label>
          <input v-model="phone" v-mask="'+7 (###) ###-##-##'" id="phone" style="width: 300px;">
        </div>
        <loading-btn :loading="transfering" @click="doTransfer" class="btn btn-warning btn-lg" style="min-width: 140px; margin-left: 100px;  margin-top: 50px; padding: 40px 20px">
          Переехать
        </loading-btn>
        <h4 class="center warn-message warn-message-info" v-if="transferSuccess">Заявка на переезд успешно оставлена</h4>
        <h4 class="center warn-message warn-message-error" v-else>{{ transferErrorMessage }}</h4>
      </div>
    </div>


  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import SelectSearch from '@/components/SelectSearch'
import api from '@/api'

export default {
  components: { SelectSearch },
  directives: { mask },
  data() {
    return {
      cityStreets: {},
      city: this.$user().city,
      street: null,
      house: null,
      flat: null,
      phone: '+7',
      formValid: false,
      transfering: false,
      transferSuccess: false,
      transferErrorMessage: '',
    }
  },
  computed: {
    cities() {
      return Object.keys(this.cityStreets)
    },
    streets() {
      return this.cityStreets[this.city] || []
    },
  },
  watch: {
    city() {
      this.street = null
    }
  },
  created() {
    if (this.$auth.check('ROLE_RTK')) {
      return
    }
    api.getStreets().then(streets => this.cityStreets = streets)
  },
  methods: {
    doTransfer() {
      this.transfering = true
      this.transferSuccess = false
      this.transferErrorMessage = null
      api.addressMovement(this.city, this.street, this.house, this.flat, this.phone)
        .then(res => {
          if (res.success) {
            this.transferSuccess = true
          } else {
            this.transferErrorMessage = res.message
          }
        })
        .catch(() => this.transferErrorMessage = 'Произошла внутреняя ошибка при попытке оставить завку. Повторите попытку позднее.')
        .then(() => this.transfering = false)
    }
  },
}
</script>

<style>
.movement #street .multiselect__tags {
  border: 1px solid #bbb;
  border-radius: 5px;
  background-color: #fcfcfc;
}
</style>

<style scoped>
#city {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpolygon fill='%23999' points='0,6 16,6 8,14' /%3e%3c/svg%3e");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 10px 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#city, #building, #flat, #phone {
  border: 1px solid #bbb;
  border-radius: 5px;
  background-color: #fcfcfc;
  padding-left: 10px;
  height: 40px;
  margin-bottom: 15px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}
</style>
