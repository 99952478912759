<template>
  <div>
    <img src="@/assets/img/glaz-logo.png" style="float: left; margin-right: 15px; margin-bottom: -15px;" />
    <div style="margin-top: 25px;">
      <table width="100%">
        <tr>
          <td><h3 style="float: left;">Онлайн-камеры в Вашем доме!</h3></td>
          <td style="text-align:right; padding-left: 10px;">
            <loading-btn class="p-button" :loading="loading" @click="glazAuth" :disabled="$auth.check('ROLE_GLAZ_DISABLED')">
              смотреть здесь
            </loading-btn>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="validation-error" style="text-align: right">
            {{ errorText }}
          </td>
        </tr>
      </table>
    </div>
    <div style="clear: both;">
      Будьте в курсе, что происходит во дворе: следите за детской площадкой или припаркованным авто.<br/>
      <h4 style="color: red;" v-if="$auth.check('ROLE_GLAZ_DISABLED')">
        ! Вам, временно приостановлен доступ к камерам вашего дома по причине блокировки лицевого счёта
      </h4>
      <div>
        <strong>Скачать приложение:</strong><br/>
        <a href="http://downloads-cdn77.iv-cdn.com/bundles/camera_viewer/CameraViewer_6.1.2_win32_setup.exe">Windows</a> |
        <a href="http://downloads-cdn77.iv-cdn.com/bundles/camera_viewer/CameraViewer_6.1.2.deb">Linux|Ubuntu(deb пакет)</a> |
        <a href="http://downloads-cdn77.iv-cdn.com/bundles/camera_viewer/CameraViewer_6.0.5_macosx-x86-64.dmg">MacOS</a> |
        <a href="https://play.google.com/store/apps/details?id=com.extcam.cameraviewer">Android</a> |
        <a href="https://itunes.apple.com/ru/app/mobile-camera-viewer/id634483607?mt=8">iOS</a>
      </div>
      <br>
      <small v-if="$auth.check('ROLE_PHYS')">*Камеры «АльянсГлаз» работают в тестовом режиме и предоставляется бесплатно.<br/></small>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      loading: false,
      errorText: '',
    }
  },
  methods: {
    glazAuth() {
      this.error = false
      this.loading = true
      api.glazAuth()
        .then(res => {
          if (res.success) {
            location = res.url
          } else {
            this.errorText = res.message
            this.loading = false
          }
        })
        .catch(() => {
          this.errorText = 'Произошла ошибка на сервере, попробуйте позже'
          this.loading = false
        })
    }
  }
}
</script>
