import Echo from 'laravel-echo'

export default {
  install(Vue) {
    const echo = new Echo({
      broadcaster: 'socket.io',
      client: require('socket.io-client'),
    })

    const listeners = {
      public: {},
      private: {},
    }

    Vue.mixin({
      mounted() {
        const subscribe = (channel, events, handlers) => {
          Object.keys(events).forEach(key => {
            if (!handlers[key]) {
              handlers[key] = []
              channel.listen(key, payload => {
                handlers[key].forEach(handler => handler.func.call(handler.ctx, payload))
              })
            }
            handlers[key].push({ctx: this, func: events[key]})
          })
        }

        const ws = this.$options.ws || {}
        if (ws.public) {
          this.wsPublic = echo.channel('public')
          subscribe(this.wsPublic, ws.public, listeners.public)
        }
        if (ws.private) {
          echo.connector.options.auth.headers.Authorization = 'Bearer ' + this.$auth.token()
          this.wsPrivate = echo.private('user.' + this.$user().basic_account)
          subscribe(this.wsPrivate, ws.private, listeners.private)
        }
      },
      beforeDestroy() {
        const unsubscribe = (channel, events, handlers) => {
          Object.keys(events).forEach(key => {
            if (handlers[key]) {
              let idx = handlers[key].findIndex(handler => handler.func === events[key])
              if (idx >= 0) {
                handlers[key].splice(idx, 1)
              }
              if (handlers[key].length === 0) {
                channel.stopListening(key)
                delete handlers[key]
              }
            }
          })
          if (Object.keys(handlers).length === 0) {
            channel.unsubscribe()
          }
        }

        const ws = this.$options.ws || {}
        if (this.wsPublic) {
          unsubscribe(this.wsPublic, ws.public, listeners.public)
        }
        if (this.wsPrivate) {
          unsubscribe(this.wsPrivate, ws.private, listeners.private)
        }
      }
  })
  }
}
