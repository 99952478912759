import dateFormat from 'dateformat'

dateFormat.i18n.monthNames = [
  'янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек',
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
]
dateFormat.i18n.dayNames = [
  'вск', 'пон', 'вт', 'ср', 'чт', 'пт', 'сб',
  'воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'
]
