<template>
  <div>
    <div style="text-align: center" v-if="typesLoading">
      <loading-spinner />
    </div>
    <div v-else>
      <div class="reports-navi">
        <router-link v-for="item in reportTypes" :key="item.name"
          :to="{name: 'reports', params: {type: item.name}}"
          class="button" :class="{'button-current': item.name === activeTypeName}"
          style="margin-left: 3px;"
        >
          {{ item.title }}
        </router-link>
        <div class="clear" />
      </div>

      <div id="reports">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mn_box">
          <tr>
            <td width="600px"  style="border:1px solid #555;">
              <form @submit.prevent="fetchReport">
                <table cellpadding="5" cellspacing="10">
                  <tr>
                      <td>
                        <label for="date-range-from">{{ activeType.title }} с</label>
                      </td>
                      <td>
                        <date-picker id="date-range-from" v-model="dateFrom" :disabled-dates="{from: dateTo}"
                          :language="ruPicker" format="dd.MM.yyyy" :disabled="reportLoading" monday-first
                        />
                      </td>
                      <td>
                        <label for="date-range-to">по</label>
                      </td>
                      <td>
                        <date-picker id="date-range-to" v-model="dateTo" :disabled-dates="{to: dateFrom}"
                          :language="ruPicker" format="dd.MM.yyyy" :disabled="reportLoading" monday-first
                        />
                      </td>
                      <td>
                        <loading-btn style="width: 100px; text-align: center;" :disabled="reportLoading">
                          Отчёт
                        </loading-btn>
                      </td>
                  </tr>
                  <tr v-if="activeType.filters">
                    <td v-for="(filterType, name) in activeType.filters" :key="name" :colspan="filterType.items ? Object.keys(filterType.items).length + 1 : 1">
                      <b>{{ filterType.title }}:</b>
                      &nbsp;
                      <template v-if="filterType.type == 'radio'">
                        <label v-for="(subTitle, subName) in filterType.items" :key="subName">
                          <input type="radio" class="radio" :name="name" :value="subName" v-model="filter[name]" />
                          {{ subTitle }}
                        </label>
                      </template>
                      <input :type="filterType.type" :name="name" v-model="filter[name]" v-else />
                    </td>
                  </tr>
                </table>
              </form>
              <p class="center warn-message warn-message-error">
                {{ reportError }}
              </p>
            </td>
          </tr>
        </table>
        <div style="text-align: center" v-if="reportLoading">
          <loading-spinner />
        </div>
        <div id="report_area" style="border:0px solid #d00;" v-if="reportTitle">
          <div class="report">
            <table  width="100%" border="0" cellspacing="1" cellpadding="0" class="report_tbl payrpt">
              <tr class="caption">
                <td colspan="10" style="text-align: center;">
                  {{ reportTitle }}
                </td>
              </tr>
              <tr class="caption">
                <td v-for="(item, key) in report.header" :key="key" v-html="item" :width="report.colsWidth && report.colsWidth[key]" />
              </tr>

              <tr v-for="(row, rowKey) in report.rows" :key="rowKey">
                <td v-for="(item, key) in row" :key="key" v-html="item" />
              </tr>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import ruPicker from 'vuejs-datepicker/dist/locale/translations/ru'
import dateFormat from 'dateformat'
import api from '@/api'

export default {
  components: { DatePicker },
  data() {
    return {
      report: {},
      reportTitle: '',
      reportError: '',
      reportLoading: false,
      ruPicker,
      dateFrom: new Date(Date.now() - 30 * 86400000),
      dateTo: new Date(),
      filter: {},
    }
  },
  computed: {
    typesLoading() {
      return this.$store.state.reportTypes.length === 0
    },
    activeTypeName() {
      return this.$route.params.type || (this.$store.state.reportTypes[0] || {}).name
    },
    activeType() {
      return this.$store.state.reportTypes.find(type => type.name === this.activeTypeName) || {}
    },
    reportTypes() {
      return this.$store.state.reportTypes
    },
    dateFromFormated() {
      return dateFormat(this.dateFrom, 'dd mmmm yyyy')
    },
    dateToFormated() {
      return dateFormat(this.dateTo, 'dd mmmm yyyy')
    },
  },
  watch: {
    $route() {
      this.fetchReport()
    }
  },
  created() {
    this.$store.dispatch('FETCH_REPORT_TYPES')
      .then(() => this.fetchReport())
  },
  methods: {
    fetchReport() {
      this.report = {}
      this.reportTitle = ''
      this.reportError = ''
      this.reportLoading = true
      api.getReport(this.activeTypeName, this.dateFrom, this.dateTo, this.filter)
        .catch(() => ({message: 'Произошла ошибка на сервере, попробуйте позже'}))
        .then(res => {
          this.reportLoading = false
          if (res.success) {
            this.report = res.report
            this.reportTitle = this.activeType.title + ' с ' + this.dateFromFormated + ' по ' + this.dateToFormated
          } else {
            this.reportError = res.message
          }
        })
    }
  },
}
</script>

<style>

</style>
