<template>
  <div>
    <h1 class="main-title">Услуга «Социальный интернет»</h1>
    <div class="body-text">
      Услуга «Социальный Интернет» дает бесплатный доступ только на социально значимые ресурсы – это государственные порталы
      («Госуслуги», сайты министерств, ведомств и местных органов власти) и другие значимые информационные ресурсы.
      <b>Доступ к любым другим интернет ресурсам блокируется на период действия услуги.</b>
      <br/><br/>
      Подключение/отключение услуги – 0 руб.
      <br/><br/>
      На период действия услуги «Социальный Интернет» абонентская плата по тарифу не взимается.
      <br/><br/>
      Если у вас подключена услуга Интерактивного ТВ (Wink или «Смотри Везде»), а также «Антивирус»,
      <b>доступ к этим услугам будет заблокирован</b> до отключения «Социального интернета».
      <br/><br/>
      Если у вас подключена услуга «Абонемент», денежные средства, списанные единовременно
      <a href="https://inetvl.ru/aktsii/abonement_/" target="_blank">по условиям услуги «Абонемент»</a>, не возвращаются, срок абонемента не продлевается.
      <br/><br/>
      <div v-if="hasMobile !== null">
        <div class="warn-common">
          <span class="warn-message warn-message-error" v-if="errorMsg">
            Ошибка:
            <span>{{ errorMsg }}</span>
          </span>
        </div>
        <div class="connect-option-form" v-if="hasMobile">
          <loading-btn class="connect-option" :loading="toggling" @click="socialInetToggle">
            {{ $user().inet.is_social ? 'Отказаться от услуги «Социальный интернет»' : 'Перейти на «Социальный интернет»' }}
          </loading-btn>
        </div>
        <div class="info-container" v-else>
          Мы не знаем Ваш мобильный телефон. Для продолжения заполните его
          <router-link :to="{name: 'contacts'}" style="font-size: 18px;">здесь</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      hasMobile: null,
      errorMsg: '',
    }
  },
  computed: {
    toggling() {
      return this.$store.getters.hasActiveAction(['Service.Link.SocialInet', 'Service.Unlink.SocialInet'])
    },
  },
  created() {
    this.$store.dispatch('FETCH_CONTACTS').then(c => this.hasMobile = !!c.mobile)
  },
  methods: {
    socialInetToggle() {
      this.errorMsg = ''
      const actName = this.$user().inet.is_social ? 'Service.Unlink.SocialInet' : 'Service.Link.SocialInet'
      this.$store.commit('START_ACTIVE_ACTION', actName)
      api.socialInetToggle()
        .catch(() => ({message: 'Произошла ошибка на сервере'}))
        .then(res => {
          if (res.success) {
            return
          }
          this.errorMsg = res.message
          this.$store.commit('COMPLETE_ACTIVE_ACTION', actName)
        })
    }
  },
}
</script>

<style scoped>
h1.main-title {
  font-family: Verdana, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
table {
  width: 100%;
}
.body-text {
  font-family: Verdana, Arial, sans-serif;
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
}
.connect-option-form {
  text-align: center;
  margin: 15px;
}
.connect-option {
  display: inline-block;
  margin: 0 auto;
  padding: 15px 25px;
  background-color: #ee2d36;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.info-container {
  text-align: center;
  margin: 25px 0;
  font-size: 18px;
  border: 1px solid #bbbbbb;
  padding: 20px 0;
  color: red;
}
</style>
