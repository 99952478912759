<template>
  <div class="loader" :style="{width: `${size}px`, height: `${size}px`}">
    <svg class="circular-loader" viewBox="25 25 50 50">
      <circle class="loader-path" cx="50" cy="50" r="20" :style="{'stroke-width': `${width}px`}"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 35
    },
    width: {
      type: Number,
      default: 5
    },
  }
}
</script>

<style scoped>
.loader {
  margin: 0px auto;
  display: flex;
}
.circular-loader {
  animation: rotate 2s linear infinite;
}
.loader-path {
  fill: none;
  stroke: currentColor;
  animation: animate-stroke 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
</style>
